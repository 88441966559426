<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    VclList,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      errored: false,
      total: 0,
      fields: [
        { key: "name", label: "Nome", sortable: true, },
        { key: "name", label: "Nome", sortable: true, },
        { key: "person_number", label: "CPF/CNPJ", sortable: true, },
        { key: "email", label: "E-mail", sortable: true, },
        { key: "cellphone", label: "Celular", sortable: true, },
        { key: "city", label: "Cidade", sortable: true, },
        { key: "status", label: "Status", sortable: true, },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,
      currentPage: "1",
      perPage: "50",
      filter: "",

      del: {
        modal: false,
        id: null,
      },
    };
  },
  methods: {
    getClients() {
      this.loading = true;

      api.get("clients/import/" + this.$route.params.id).then((response) => {
        if (response.data.status == 'success') {
          this.total = response.data.total;
          this.items = response.data.list;
        } else {
          this.$router.push('/clients');
        }
      })
        .catch((error) => {
          this.errored = error;
          this.$router.push('/clients');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDelete(id) {
      this.del.modal = true;
      this.del.id = id;
    },
    delCliente() {
      if (this.del.id) {
        this.del.modal = false;

        api.delete("clients/" + this.del.id).then((response) => {
          if (response.data.status == 'success') {
            this.getClients();
            this.$toast.success('O cliente foi excluído com sucesso.');
          }
        })
      }
    },
  },
  mounted() {
    this.getClients();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/clients">Clientes</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block"><router-link tag="a" to="/clients/import">Importar clientes</router-link></li>
      <li class="breadcrumb-item d-none d-sm-block">Últimas importações</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Últimas importações</h3>
        <p></p>
      </div>
      <div class="page-title-right">
        <div class="card border-0 bg-soft-default mb-0">
          <div class="card-body py-0 d-flex align-items-center">
            <div class="font-size-15 py-3">Total de clientes:</div>
            <h4 class="m-0 ml-2 py-3">{{ this.total }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="card min-vh-50">
      <div class="card-body">
        <div v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="items && items.length == 0" class="text-center">
          Nenhum cliente encontrado.
        </div>
        <div v-else class="table-responsive">
          <b-input v-model="filter" placeholder="Busca" class="mb-4"></b-input>
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover min-vh-50" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :current-page="currentPage" :per-page="perPage" :filter="filter" responsive>
            <template #cell(cellphone)="row">
              {{ row.item.cellphone }}
            </template>
            <template #cell(city)="row">
              {{ row.item.city }}/{{ row.item.state }}
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status == 'correct'" class="bx bx-check-double font-size-22 text-success align-middle"></span>
              <span v-else-if="row.item.status == 'duplicate'" class="bx bx-duplicate font-size-22 text-warning align-middle"></span>
              <span v-else class="bx bx-error-alt font-size-22 text-danger align-middle"></span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" pills align="center"></b-pagination>

    <b-modal v-model="del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Excluir cliente</h4>
        <p>Confirma exclusão do cliente?</p>
        <div class="d-flex">
          <button class="btn btn-default mr-3" v-on:click="delCliente">Confirmar</button>
          <button class="btn btn-outline-link" v-on:click="del.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>